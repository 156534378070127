var render = function () {var _vm=this;var _h=_vm.$createElement;var _c=_vm._self._c||_h;return _c('v-form',{ref:"form"},[_c('v-dialog',{attrs:{"persistent":"","width":"350"},scopedSlots:_vm._u([{key:"activator",fn:function(ref){
var ondialog = ref.on;
return [_c('v-tooltip',{attrs:{"bottom":""},scopedSlots:_vm._u([{key:"activator",fn:function(ref){
var ontooltip = ref.on;
var attrs = ref.attrs;
return [_c('v-btn',_vm._g(_vm._b({attrs:{"id":"template-duplicate__dup-btn","icon":""}},'v-btn',attrs,false),Object.assign({}, ontooltip, ondialog)),[_c('v-icon',{attrs:{"small":""}},[_vm._v(" mdi-content-copy ")])],1)]}}],null,true)},[_c('span',[_vm._v("Duplicate")])])]}}]),model:{value:(_vm.dialog),callback:function ($$v) {_vm.dialog=$$v},expression:"dialog"}},[_c('v-card',[_c('v-card-title',[_vm._v(" Duplicate "+_vm._s(_vm.identifier)+"? ")]),_c('v-card-text',[_vm._v(" Do you want to create a template based on this template? If yes, give it a name. "),_c('v-text-field',{staticClass:"mt-5",attrs:{"id":"template-duplicate__input-name","label":_vm.templateNameField.label,"counter":_vm.maxNameLength,"rules":[
            _vm.templateNameField.rules.required,
            _vm.templateNameField.rules.counter ],"error-messages":_vm.errorMessages,"disabled":_vm.duplicateLoading,"loading":_vm.loading},on:{"input":_vm.onInputName},model:{value:(_vm.templateName),callback:function ($$v) {_vm.templateName=$$v},expression:"templateName"}})],1),_c('v-divider'),_c('v-card-actions',[_c('v-spacer'),_c('v-btn',{attrs:{"id":"template-duplicate__cancel-btn","disabled":_vm.loading || _vm.duplicateLoading,"text":""},on:{"click":function($event){return _vm.closeDialog()}}},[_vm._v(" Cancel ")]),_c('v-btn',{staticClass:"template-duplicate_duplicate-btn",attrs:{"id":"template-duplicate__create-btn","color":"primary","text":"","loading":_vm.duplicateLoading,"disabled":!_vm.validateTemplateName || _vm.loading},on:{"click":function($event){return _vm.duplicateTemplate()}}},[_vm._v(" Create ")])],1)],1)],1)],1)}
var staticRenderFns = []

export { render, staticRenderFns }