


















































import { defineComponent, ref } from '@vue/composition-api';
import router from '@/router';

export default defineComponent({
  props: {
    identifier: {
      type: String,
      required: true,
    },
    templateId: {
      type: String,
      required: true,
    },
    version: {
      type: Number,
      required: true,
    },
  },

  /**
     * Main fn to edit current version
     */
  setup(props) {
    const dialog = ref(false);

    /**
       * Closes dialog box
       */
    const closeDialog = () => {
      dialog.value = false;
    };

    const goToEditVersion = () => {
      router.push({
        name: 'TemplateCustomisation',
        params: {
          templateId: props.templateId,
          version: props.version.toString(),
        },
      });
    };
    return {
      dialog,
      closeDialog,
      goToEditVersion,
    };
  },
});
