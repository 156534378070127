
























































































































































































import {
  defineComponent,
  computed,
  ref,
  reactive,
} from '@vue/composition-api';
import Store from '@/store';
import { getElementId } from '@/utils';
import { ITemplateVersionListRecord } from '@/types';
import VersionDuplicateDialog from './VersionDuplicateDialog.vue';
import VersionEditDialog from './VersionEditDialog.vue';

export default defineComponent({
  components: {
    VersionDuplicateDialog,
    VersionEditDialog,
  },
  props: {
    templateName: {
      type: String,
      required: true,
    },
    templateId: {
      type: String,
      required: true,
    },
  },

  setup(props) {
    const dialog = ref(false);

    const headers = [
      {
        text: 'Name',
        align: 'start',
        value: 'identifier',
      },
      {
        text: 'Description',
        value: 'description',
      },
      {
        text: 'Created By',
        value: 'createdBy',
      },
      {
        text: 'Version',
        value: 'version',
      },
      {
        text: 'Last Change',
        value: 'updatedAt',
      },
      {
        text: 'Actions',
        value: 'actions',
        sortable: false,
      },
    ];

    const datePicker = reactive({
      updatedAt: {
        value: [] as string[],
        menu: false,
      },
    });

    /**
     * Closes Updated At datepicker menu
     */
    const onUpdatedAtCancel = () => {
      datePicker.updatedAt.menu = false;
    };

    /**
     * Sets our template ID in our store when user opens dialog
     */
    const setTemplateId = () => {
      Store.dispatch('versionList/setTemplateId', props.templateId);
    };

    /**
     * Calls our Vuex store to load data from api using template Id
     */
    const getListItems = () => {
      setTemplateId();
      Store.dispatch('versionList/loadData');
    };

    /**
     * Updates filter updatedAt in the VersionListStore and calls getListItems()
     * @param {boolean} [triggerSearch=true] Doesn't trigger getListItems if false
     */
    const onUpdatedAtOk = (triggerSearch = true) => {
      const dates = datePicker.updatedAt.value;
      if (dates.length > 1) {
        Store.dispatch('versionList/setFilterUpdatedAt', dates);
        if (triggerSearch) getListItems();
      }
      datePicker.updatedAt.menu = false;
    };

    /**
     * Clears updated at filter and triggers getListItems()
     * @param {boolean} [triggerSearch=true] Doesn't trigger getListItems if false
     */
    const onUpdatedAtClear = (triggerSearch = true) => {
      datePicker.updatedAt.value = [];
      Store.dispatch('versionList/setFilterUpdatedAt', '');
      if (triggerSearch) getListItems();
    };

    const listItems = computed(
      (): ITemplateVersionListRecord[] => Store.getters['versionList/getData'],
    );
    const versionsTotal = computed(
      (): number => Store.getters['versionList/getTotal'],
    );
    const isLoading = computed(
      (): boolean => Store.getters['versionList/getLoading'],
    );
    const filterObject = computed(() => Store.getters['versionList/getFilter']);

    /**
     * Gets the new number of items epr page and updates the data pagination.itemsPerPage in the
     * VersionListStore and calls getListItems()
     * @param {number} itemsPerPage Items per page
     * @return {void}
     */
    const onItemsPerPageChange = (itemsPerPage: number) => {
      Store.dispatch('versionList/setPaginationItemsPerPage', itemsPerPage);
      getListItems();
    };

    /**
     * Gets descendent boolean and updates filter.isDesc in the
     * VersionListStore and calls getListItems()
     * @param {boolean[]} sortBy Items per page
     */
    const onColSortDesc = (isDesc: boolean[]) => {
      const isDescValue = isDesc ? '-' : '';
      Store.dispatch('versionList/setFilterIsDesc', isDescValue);
      getListItems();
    };

    /**
     * Gets new sort by value and updates filter.sortBy in the VersionListStore
     * and calls getListItems()
     * @param {string} sortBy Items per page
     */
    const onColSortBy = (sortBy: string) => {
      const sortByValue = sortBy || '';
      Store.dispatch('versionList/setFilterSortBy', sortByValue);
      getListItems();
    };

    const pagination = computed(() => Store.getters['versionList/getPagination']);

    /**
     * Gets the new page and updates the data pagination.page in the VersionListStore
     * and calls getListItems()
     * @param {number} page New page number
     */
    const onPageChange = (page: number) => {
      Store.dispatch('versionList/setPaginationPage', page);
      getListItems();
    };

    /**
     * Returns number of versions of specific template
     * Placeholder for api call
     * @return {String} number of versions returned from api
     */
    const getVersionsTotal = computed((): string => (versionsTotal.value > 1
      ? `${versionsTotal.value} versions`
      : `${versionsTotal.value} version`));

    /**
     * Calls our get list items fn to load data into table
     */
    const onFilterChange = () => {
      getListItems();
    };

    /**
     * Closes dialog when Close Btn clicked
     * Resets the state to default values in vuex
     */
    const closeDialog = () => {
      Store.dispatch('versionList/resetStoreState');
      Store.dispatch('versionList/resetTemplateId');
      dialog.value = false;
    };

    return {
      dialog,
      headers,
      listItems,
      datePicker,
      onUpdatedAtCancel,
      onUpdatedAtOk,
      onUpdatedAtClear,
      versionsTotal,
      getVersionsTotal,
      filterObject,
      isLoading,
      closeDialog,
      getListItems,
      onFilterChange,
      getElementId,
      onItemsPerPageChange,
      pagination,
      onColSortDesc,
      onColSortBy,
      onPageChange,
    };
  },
});
