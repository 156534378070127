






import { defineComponent } from '@vue/composition-api';
import TemplateListTable from '@/components/TemplateListTable.vue';

export default defineComponent({
  components: {
    TemplateListTable,
  },
});
