var render = function () {var _vm=this;var _h=_vm.$createElement;var _c=_vm._self._c||_h;return _c('v-dialog',{scopedSlots:_vm._u([{key:"activator",fn:function(ref){
var ondialog = ref.on;
return [_c('v-tooltip',{attrs:{"bottom":""},scopedSlots:_vm._u([{key:"activator",fn:function(ref){
var ontooltip = ref.on;
var attrs = ref.attrs;
return [_c('v-btn',_vm._g(_vm._b({staticClass:"template-version-dialog__open-btn",attrs:{"id":_vm.getElementId(_vm.templateId, 'template-version-dialog__open-btn'),"icon":""},on:{"click":function($event){return _vm.getListItems()}}},'v-btn',attrs,false),Object.assign({}, ontooltip, ondialog)),[_c('v-icon',{attrs:{"small":""}},[_vm._v(" mdi-history ")])],1)]}}],null,true)},[_c('span',[_vm._v("Versions")])])]}}]),model:{value:(_vm.dialog),callback:function ($$v) {_vm.dialog=$$v},expression:"dialog"}},[_c('v-card',[_c('v-card-title',[_vm._v(" "+_vm._s(_vm.templateName)+" Version List ")]),_c('v-card-subtitle',[_vm._v(_vm._s(_vm.getVersionsTotal))]),_c('v-card-text',[_c('v-data-table',{staticClass:"version-list-table",attrs:{"headers":_vm.headers,"items":_vm.listItems,"loading":_vm.isLoading,"page":_vm.pagination.page,"items-per-page":_vm.pagination.itemsPerPage,"server-items-length":_vm.pagination.serverItemsLength},on:{"update:page":[function($event){return _vm.$set(_vm.pagination, "page", $event)},_vm.onPageChange],"update:serverItemsLength":function($event){return _vm.$set(_vm.pagination, "serverItemsLength", $event)},"update:server-items-length":function($event){return _vm.$set(_vm.pagination, "serverItemsLength", $event)},"update:items-per-page":_vm.onItemsPerPageChange,"update:sort-by":_vm.onColSortBy,"update:sort-desc":_vm.onColSortDesc},scopedSlots:_vm._u([{key:"header.identifier",fn:function(ref){
var header = ref.header;
return [_c('v-text-field',{staticClass:"d-inline-block font-weight-regular",attrs:{"data-column-filter":"template-version-name","label":header.text,"clearable":""},on:{"click":function($event){$event.stopPropagation();},"input":function($event){return _vm.onFilterChange()}},model:{value:(_vm.filterObject.identifier),callback:function ($$v) {_vm.$set(_vm.filterObject, "identifier", $$v)},expression:"filterObject.identifier"}})]}},{key:"header.description",fn:function(ref){
var header = ref.header;
return [_c('v-text-field',{staticClass:"d-inline-block font-weight-regular",attrs:{"data-column-filter":"template-version-description","label":header.text,"clearable":""},on:{"click":function($event){$event.stopPropagation();},"input":function($event){return _vm.onFilterChange()}},model:{value:(_vm.filterObject.description),callback:function ($$v) {_vm.$set(_vm.filterObject, "description", $$v)},expression:"filterObject.description"}})]}},{key:"header.createdBy",fn:function(ref){
var header = ref.header;
return [_c('v-text-field',{staticClass:"d-inline-block font-weight-regular",attrs:{"data-column-filter":"template-version-created-by","label":header.text,"clearable":""},on:{"click":function($event){$event.stopPropagation();},"input":function($event){return _vm.onFilterChange()}},model:{value:(_vm.filterObject.createdBy),callback:function ($$v) {_vm.$set(_vm.filterObject, "createdBy", $$v)},expression:"filterObject.createdBy"}})]}},{key:"header.version",fn:function(ref){
var header = ref.header;
return [_c('v-text-field',{staticClass:"d-inline-block font-weight-regular",attrs:{"data-column-filter":"template-version-version","label":header.text,"clearable":""},on:{"click":function($event){$event.stopPropagation();},"input":function($event){return _vm.onFilterChange()}},model:{value:(_vm.filterObject.version),callback:function ($$v) {_vm.$set(_vm.filterObject, "version", $$v)},expression:"filterObject.version"}})]}},{key:"header.updatedAt",fn:function(ref){
var header = ref.header;
return [_c('v-menu',{attrs:{"close-on-content-click":false,"transition":"scale-transition","offset-y":"","min-width":"auto"},scopedSlots:_vm._u([{key:"activator",fn:function(ref){
var on = ref.on;
var attrs = ref.attrs;
return [_c('v-text-field',_vm._g(_vm._b({staticClass:"d-inline-block font-weight-regular",attrs:{"data-column-filter":"template-version-updated-at","label":header.text,"clearable":"","readonly":""},on:{"click:clear":function($event){return _vm.onUpdatedAtClear()}},model:{value:(_vm.filterObject.updatedAt),callback:function ($$v) {_vm.$set(_vm.filterObject, "updatedAt", $$v)},expression:"filterObject.updatedAt"}},'v-text-field',attrs,false),on))]}}],null,true),model:{value:(_vm.datePicker.updatedAt.menu),callback:function ($$v) {_vm.$set(_vm.datePicker.updatedAt, "menu", $$v)},expression:"datePicker.updatedAt.menu"}},[_c('v-date-picker',{attrs:{"scrollable":"","range":""},model:{value:(_vm.datePicker.updatedAt.value),callback:function ($$v) {_vm.$set(_vm.datePicker.updatedAt, "value", $$v)},expression:"datePicker.updatedAt.value"}},[_c('v-spacer'),_c('v-btn',{attrs:{"text":"","color":"secondary"},on:{"click":function($event){return _vm.onUpdatedAtCancel()}}},[_vm._v(" Cancel ")]),_c('v-btn',{attrs:{"text":"","color":"primary"},on:{"click":function($event){return _vm.onUpdatedAtOk()}}},[_vm._v(" OK ")])],1)],1)]}},{key:"header.actions",fn:function(ref){
var header = ref.header;
return [_c('span',{staticClass:"d-sr-only"},[_vm._v(_vm._s(header.text))])]}},{key:"item.updatedAt",fn:function(ref){
var item = ref.item;
return [_c('span',[_vm._v(_vm._s(new Date(item.updatedAt).toLocaleString().replace(',', '')))])]}},{key:"item.actions",fn:function(ref){
var item = ref.item;
return [_c('div',{staticClass:"d-flex justify-space-around justify-lg-start"},[_c('VersionDuplicateDialog',{staticClass:"version-list__duplicate-btn",attrs:{"identifier":item.identifier,"version":item.version,"versions-total":_vm.versionsTotal},on:{"reloadVersions":function($event){return _vm.getListItems()}}}),_c('VersionEditDialog',{staticClass:"version-list__edit-btn",attrs:{"identifier":item.identifier,"template-id":item.templateId,"version":item.version}})],1)]}}],null,true)})],1),_c('v-card-actions',{staticClass:"d-flex justify-end py-5"},[_c('v-btn',{staticClass:"version-list__close-btn",attrs:{"color":"primary"},on:{"click":function($event){return _vm.closeDialog()}}},[_vm._v(" Close ")])],1)],1)],1)}
var staticRenderFns = []

export { render, staticRenderFns }