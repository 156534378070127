

































































































































































































































































































































































































































import {
  reactive,
  ref,
  defineComponent,
  computed,
} from '@vue/composition-api';
import Store from '@/store';
import TemplateDuplicateDialog from '@/components/TemplateDuplicateDialog.vue';
import router from '@/router';
import { getElementId } from '@/utils';
import { IVComboboxItem } from '@/types';
import VersionListDialog from './VersionList/VersionListDialog.vue';

export default defineComponent({
  components: {
    VersionListDialog,
    TemplateDuplicateDialog,
  },
  props: {
    tableType: {
      type: String,
      default: 'template',
    },
  },
  setup(props) {
    const headers = [
      { text: 'Name', align: 'start', value: 'identifier' },
      { text: 'Status', value: 'published' },
      { text: 'Version', value: 'version' },
      { text: 'Created By', value: 'createdByName' },
      { text: 'Created On', value: 'createdAt' },
      { text: 'Last Change', value: 'updatedAt' },
      { text: 'Actions', value: 'actions', sortable: false },
    ];

    const statusOptions: IVComboboxItem[] = [
      { text: 'Published', value: true },
      { text: 'Unpublished', value: false },
    ];

    const paginationChanging = ref(false);

    const isLoading = computed(() => Store.getters['templateList/getLoading']);
    const listItems = computed(() => Store.getters['templateList/getData']);
    const pagination = computed(
      () => Store.getters['templateList/getPagination'],
    );
    const filterObject = computed(() => Store.getters['templateList/getFilter']);

    const openedPanel = ref([]);

    const datePicker = reactive({
      createdAt: {
        value: [] as string[],
        menu: false,
        dialog: false,
      },
      updatedAt: {
        value: [] as string[],
        menu: false,
        dialog: false,
      },
    });

    /**
       * Updates filters in the TemplateListStore and calls filter()
       */
    const getListItems = async () => {
      openedPanel.value = [];
      await Store.dispatch('templateList/filter');
    };

    /**
     * @summary If we're in the message log view, need to show only published templates
     * @author Ewa Murjas
     */
    const setPublishedFilter = () => {
      if (props.tableType === 'messageLog') {
        Store.dispatch('templateList/setFilterPublished', true);
      } else {
        Store.dispatch('templateList/setFilterPublished', null);
      }
    };

    /**
     * Gets the new page and updates the data pagination.page in the TemplateListStore
     * and calls getListItems()
     * @param {number} page New page number
     */
    const onPageChange = async (page: number) => {
      if (paginationChanging.value) return;
      paginationChanging.value = true;
      await Store.dispatch('templateList/setPaginationPage', page);
      await getListItems();

      paginationChanging.value = false;
    };

    /**
     * Gets the new number of items epr page and updates the data pagination.itemsPerPage
     * in the TemplateListStore and calls getListItems()
     * @param {number} itemsPerPage Items per page
     * @return {void}
     */
    const onItemsPerPageChange = async (itemsPerPage: number) => {
      if (paginationChanging.value) return;
      paginationChanging.value = true;
      await Store.dispatch('templateList/setPaginationItemsPerPage', itemsPerPage);
      await getListItems();
      paginationChanging.value = false;
    };

    /**
       * Updates pagination.page to 1 in the TemplateListStore and calls getListItems()
       */
    const onFilterChange = () => {
      Store.dispatch('templateList/setPaginationPage', 1);
      getListItems();
    };

    /**
     * Gets new sort by value and updates filter.sortBy in the TemplateListStore
     * and calls getListItems()
     * @param {string} sortBy Items per page
     */
    const onColSortBy = (sortBy: string) => {
      const sortByValue = sortBy || '';
      Store.dispatch('templateList/setFilterSortBy', sortByValue);
      getListItems();
    };

    /**
     * Gets descendent boolean and updates filter.isDesc in the TemplateListStore
     * and calls getListItems()
     * @param {boolean} sortBy Items per page
     */
    const onColSortDesc = (isDesc: boolean) => {
      const isDescValue = isDesc ? '-' : '';
      Store.dispatch('templateList/setFilterIsDesc', isDescValue);
      getListItems();
    };

    /**
       * Closses Created At datepicker menu
       */
    const onCreatedAtCancel = () => {
      datePicker.createdAt.menu = false;
      datePicker.createdAt.dialog = false;
    };

    /**
       * Updates filter createdAt in the TemplateListStore and calls getListItems()
       * @param {boolean} [triggerSearch=true] Doesn't trigger getListItems if false
       */
    const onCreatedAtOk = (triggerSearch = true) => {
      let dates = datePicker.createdAt.value;

      if (dates[0] > dates[1]) {
        dates = [dates[1], dates[0]];
      }

      if (dates.length > 1) {
        Store.dispatch('templateList/setFilterCreatedAt', dates);

        if (triggerSearch) getListItems();
      }

      datePicker.createdAt.menu = false;
      datePicker.createdAt.dialog = false;
    };

    /**
       * Clears created at filter and triggers getListItems()
       * @param {boolean} [triggerSearch=true] Doesn't trigger getListItems if false
       */
    const onCreatedAtClear = (triggerSearch = true) => {
      datePicker.createdAt.value = [];
      Store.dispatch('templateList/setFilterCreatedAt', '');
      if (triggerSearch) getListItems();
    };

    /**
      * Closses Updated At datepicker menu
    */
    const onUpdatedAtCancel = () => {
      datePicker.updatedAt.menu = false;
      datePicker.updatedAt.dialog = false;
    };

    /**
       * Updates filter updatedAt in the TemplateListStore and calls getListItems()
       * @param {boolean} [triggerSearch=true] Doesn't trigger getListItems if false
       */
    const onUpdatedAtOk = (triggerSearch = true) => {
      const dates = datePicker.updatedAt.value;

      if (dates.length > 1) {
        Store.dispatch('templateList/setFilterUpdatedAt', dates);

        if (triggerSearch) getListItems();
      }

      datePicker.updatedAt.menu = false;
      datePicker.updatedAt.dialog = false;
    };

    /**
       * Clears updated at filter and triggers getListItems()
       * @param {boolean} [triggerSearch=true] Doesn't trigger getListItems if false
       */
    const onUpdatedAtClear = (triggerSearch = true) => {
      datePicker.updatedAt.value = [];
      Store.dispatch('templateList/setFilterUpdatedAt', '');
      if (triggerSearch) getListItems();
    };

    /**
       * Open the customisation page for the user and pass through template id as param
       */
    const goToCustomisation = (itemId: string) => {
      router.push({
        name: 'TemplateCustomisation',
        params: { templateId: itemId },
      });
    };

    const goToMessageLogs = (itemId: string) => {
      router.push({
        name: 'MessageLogs',
        params: { templateId: itemId },
      });
    };

    setPublishedFilter();
    getListItems();

    return {
      headers,
      statusOptions,
      isLoading,
      listItems,
      pagination,
      openedPanel,
      datePicker,
      filterObject,
      getElementId,
      getListItems,
      onPageChange,
      onItemsPerPageChange,
      onFilterChange,
      onColSortBy,
      onColSortDesc,
      onCreatedAtCancel,
      onCreatedAtOk,
      onCreatedAtClear,
      onUpdatedAtCancel,
      onUpdatedAtOk,
      onUpdatedAtClear,
      goToCustomisation,
      goToMessageLogs,
      paginationChanging,
    };
  },
});
