





































































import {
  defineComponent, ref, reactive, computed,
} from '@vue/composition-api';
import Store from '@/store';

export default defineComponent({
  props: {
    identifier: {
      type: String,
      required: true,
    },
    version: {
      type: Number,
      default: 0,
    },
    versionsTotal: {
      type: Number,
      default: 0,
    },
  },

  setup(props) {
    const dialog = ref(false);
    const loading = computed(() => Store.getters['versionList/getLoading']);
    const newVersionData = reactive({
      identifier: '',
      version: props.version,
      description: '',
    });
    const form = ref();
    const maxNameLength = 100;
    const maxDescriptionLength = 100;

    /**
     * this is called when a user is copying a version of a template it sets
     * the name and description
     */
    const copyVersion = () => {
      const nextNumber = props.versionsTotal ? props.versionsTotal + 1 : 1;
      newVersionData.identifier = `${props.identifier} ${nextNumber}`;
      newVersionData.description = `Duplicated from version ${props.version}`;
    };

    /**
     * Closes dialog box
     */
    const closeDialog = () => {
      dialog.value = false;
      form.value.reset();
    };

    /**
     * Main fn to duplicate current version
     */
    const duplicateVersion = async () => {
      if (form.value.validate()) {
        await Store.dispatch('versionList/duplicateTemplates', newVersionData);
        closeDialog();
      }
    };

    const nameRules = [
      (v: string) => !!v || 'Template name is required',
      (v: string) => (v && v.length <= maxNameLength)
          || `Template name must be less than ${maxNameLength} characters`,
    ];

    const descriptionRules = [
      (v: string) => v == null
          || v.length <= maxDescriptionLength
          || `Template description must be less than ${
            maxDescriptionLength
          } characters`,
    ];

    return {
      dialog,
      loading,
      duplicateVersion,
      closeDialog,
      nameRules,
      descriptionRules,
      maxNameLength,
      maxDescriptionLength,
      form,
      copyVersion,
      newVersionData,
    };
  },
});
