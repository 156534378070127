













































































import { defineComponent, ref, computed } from '@vue/composition-api';
import Store from '@/store';
import { delayCall } from '../utils';

export default defineComponent({
  props: {
    identifier: {
      type: String,
      required: true,
    },
    templateId: {
      type: String,
      required: true,
    },
    companyId: {
      type: Number,
      required: true,
    },
  },
  emit: ['close'],
  setup(props, { emit }) {
    const dialog = ref(false);
    const loading = computed(() => Store.getters['workOrderTemplate/getLoading']);
    const duplicateLoading = computed(() => Store.getters['workOrderTemplate/getDuplicateLoading']);
    const templateName = ref(
      Store.getters['workOrderTemplate/getData'].name as string,
    );
    const form = ref();
    const maxNameLength = 100;

    const errorMessages = ref('');

    const templateNameField = {
      label: 'Template Name',
      counter: maxNameLength,
      rules: {
        /**
         * Checks if value is not falsy
         * @param {string} value Value to be checked
         * @return {boolean | string} True if valid or a text if false
         */
        required: (value: string): boolean | string => !!value || 'Template name is required',

        /**
         * Checks if value is lesser or equal to 100 characters
         * @param {string} value Value to be checked
         * @return {boolean | string} True and string if lesser or equal
         */
        counter: (value: string): boolean | string => {
          if (!value) return false;
          return value.length <= maxNameLength || 'Max 100 characters';
        },
      },
    };

    /**
     * Set the input field error message prop
     */
    const setErrorMessages = (value: string) => {
      errorMessages.value = value;
    };

    /**
     * Checks if template name field is valid
     * @return {boolean} True if valid
     */
    const validateTemplateName = computed((): boolean => {
      const required = templateNameField.rules.required(templateName.value);
      const counter = templateNameField.rules.counter(templateName.value);
      const isAvailable = Store.getters['workOrderTemplate/getValidation'].name;

      /**
       * Because name availability is an async call it can't be part of the fields
       * rules, so this is an workaround using error-messages prop
       */
      if (typeof isAvailable === 'string' && !duplicateLoading.value) setErrorMessages(isAvailable);
      else setErrorMessages('');

      return (
        !loading.value
          && typeof required === 'boolean'
          && required
          && typeof counter === 'boolean'
          && counter
          && typeof isAvailable === 'boolean'
          && isAvailable
      );
    });

    /**
     * Validate form and close the dialog. Emit 'close' event.
     */
    const validateForm = () => form.value.validate();

    /**
     * On template name field input update the name in store
     * @param {string} value Field value
     */
    const onInputName = (value: string) => {
      if (!validateForm()) return;

      delayCall(() => {
        Store.dispatch('workOrderTemplate/setName', value);
        Store.dispatch('workOrderTemplate/validateName');
      }, 750);
    };

    /**
     * Closes dialog box, resets the form and the template name in vuex store
     */
    const closeDialog = () => {
      dialog.value = false;
      Store.dispatch('workOrderTemplate/setName', '');
      form.value.reset();
    };

    /**
     * Duplicate the selected template and emit the 'close' event to trigger
     * table refresh
     */
    const duplicateTemplate = async () => {
      await Store.dispatch('workOrderTemplate/duplicateTemplate', {
        templateId: props.templateId,
        companyId: props.companyId,
      });
      closeDialog();
      emit('close');
    };

    return {
      dialog,
      loading,
      closeDialog,
      form,
      maxNameLength,
      validateForm,
      onInputName,
      duplicateTemplate,
      validateTemplateName,
      errorMessages,
      templateNameField,
      templateName,
      duplicateLoading,
    };
  },
});
